import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  file: string;
}

export default ({ file, ...rest }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const edge = data.images.edges.find((e: any) => e.node.relativePath === file);
  const fluid = edge.node.childImageSharp.fluid;

  if (!fluid) throw new Error(`cannot find image file at ${file}`);

  return <Img fluid={fluid} {...rest} />;
};
