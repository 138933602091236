import React from 'react';
import './hero.scss';

export default () => (
  <div className="next-steps__hero">
    <h1 className="next-steps__hero-heading hidden-mobile">Next steps.</h1>
    <p className="next-steps__hero-text">
      <span className="next-steps__hero-heading show-mobile">Next steps.</span>
      You’ve decided to get out of your lease and we couldn’t be more excited!
      Getting out of the  wheel is such a great financial choice. Here’s
      how to keep the momentum going.
    </p>
    <p className="next-steps__hero-text">
      We recommend tackling this over a weekend.
    </p>
  </div>
);
