import { useStaticQuery, graphql } from 'gatsby';
import useConstant from 'use-constant';

interface Query {
  allAirtable: {
    edges: Array<{
      node: {
        data: {
          key: string;
          active: boolean;
        };
      };
    }>;
  };
}

export function useFeatureFlags() {
  const data: Query = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "flags" } }) {
        edges {
          node {
            data {
              key
              active
            }
          }
        }
      }
    }
  `);

  const flags = useConstant(() => {
    const acc: { [flag: string]: boolean } = {};
    const nodes = data.allAirtable.edges.map(e => e.node.data);

    for (const node of nodes) {
      acc[node.key] = node.active || false;
    }

    return acc;
  });

  return flags;
}
