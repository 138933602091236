import React from 'react';
import Image from '../image';
import './testimonials.scss';

export default () => (
  <div className="testimonials">
    <div>
      <h1>People really do this?</h1>
      <p>
        You bet. Every day. Read a few of the case studies below to see how
        everyday folks found themselves in a mess, and what they did to get
        themselves out.
      </p>
      <div className="testimonials__videos">
        <a href="#" className="video">
          <Image file="testimonial-1.jpg" />
          <div className="video__details">
            <div className="video__name">christi</div>
            <div className="video__title">It seemed safer</div>
            <div className="video__description">
              We ran all the numbers last night. It was a blood bath.
            </div>
          </div>
        </a>
        <a href="#" className="video">
          <Image file="testimonial-2.jpg" />
          <div className="video__details">
            <div className="video__name">jen</div>
            <div className="video__title">0% APR Financing</div>
            <div className="video__description">
              36 payments turned out to be not-so-easy.
            </div>
          </div>
        </a>
        <a href="#" className="video">
          <Image file="testimonial-3.jpg" />
          <div className="video__details">
            <div className="video__name">timothy</div>
            <div className="video__title">On leasing their first car</div>
            <div className="video__description">
              We ran all the numbers last night. It was a blood bath.
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
);
