import React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { getEstimate, EstimateState } from '../../store/estimate';
import './timeline.scss';

import Alert from '../alert';

export default () => {
  const estimate = useSelector(getEstimate);

  if (estimate.state === EstimateState.Pending) {
    return (
      <div style={{ paddingBottom: '200px' }}>
        <Alert>
          Looks like you still need to fill out some information{' '}
          <Link to="/get-started">here</Link>.
        </Alert>
      </div>
    );
  }

  // estimates with 0/positive equity that don't need a replacement car
  // can't get to this page, so we can assume in this block that they
  // require a replacement vehicle, and that the replacement vehicle
  // costs more than their total positive equity
  if (estimate.equity >= 0) {
    return (
      <div className="timeline">
        <div className="timeline__step">
          <div className="timeline__step-ordinal">1</div>
          <p>
            Find that ${estimate.replacementCost} car. Look on Craigslist, ask
            around or get creative. Good pre-owned cars are out there! If you
            have the cash, GREAT! If not, go to your local Credit Union and get
            a small loan. They’ll walk you through that process. It’s easy!
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">2</div>
          <p>
            While at the Credit Union you’ll need to get a personal loan to
            complement your positive equity. From what you told us earlier
            you’ll need about ${estimate.replacementCost - estimate.equity}.
            You’ll take this with you when you sell your lease. Ask for a
            cashier’s check made out to wherever you got your ACV quote (CarMax,
            Carvana, etc).
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">3</div>
          <p>
            Buy your ${estimate.replacementCost} pre-owned car. Use cash or the
            money you borrowed from the Credit Union.
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">4</div>
          <p>
            Go sell your lease! Take your leased car to wherever you got your
            ACV quote! Ask a friend to pick you up or ride-share home. You’re
            done! Now you're out of your lease and you’ve got a nice new-to-you
            pre-owned car. CONGRATS!
          </p>
        </div>
      </div>
    );
  }

  if (estimate.replacementCost > 0) {
    return (
      <div className="timeline">
        <div className="timeline__step">
          <div className="timeline__step-ordinal">1</div>
          <p>
            Find that ${estimate.replacementCost} car. Look on Craigslist, ask
            around or get creative. Good pre-owned cars are out there! If you
            have the cash, GREAT! If not, go to your local Credit Union and get
            a small loan. They’ll walk you through that process. It’s easy!
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">2</div>
          <p>
            While at the Credit Union you’ll need to get a personal loan for
            your negative equity too. From what you told us earlier you’ll need
            about ${Math.abs(estimate.equity)}. You’ll take this with you when
            you sell your lease. Ask for a cashier’s check made out to wherever
            you got your ACV quote (CarMax, Carvana, etc).
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">3</div>
          <p>
            Buy your ${estimate.replacementCost} pre-owned car. Use cash or the
            money you borrowed from the Credit Union.
          </p>
        </div>
        <div className="timeline__step">
          <div className="timeline__step-ordinal">4</div>
          <p>
            Go sell your lease! Take your cashier’s check ($
            {Math.abs(estimate.equity)}) and your leased car to wherever you got
            your ACV quote! Ask a friend to pick you up or ride-share home.
            You’re done! Now you're out of your lease and you’ve got a nice
            new-to-you pre-owned car. CONGRATS!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="timeline">
      <div className="timeline__step">
        <div className="timeline__step-ordinal">1</div>
        <p>
          Go to your local Credit Union and get a personal loan for your
          negative equity. From what you told us earlier you’ll need about $
          {Math.abs(estimate.equity)}. You’ll take this with you when you sell
          your lease. Ask for a cashier’s check made out to wherever you got
          your ACV quote (CarMax, Carvana, etc).
        </p>
      </div>
      <div className="timeline__step">
        <div className="timeline__step-ordinal">2</div>
        <p>
          Go sell your lease! Take your cashier’s check ($
          {Math.abs(estimate.equity)}) and your leased car to wherever you got
          your ACV quote! Ask a friend to pick you up or ride-share home. You’re
          done! Now you're out of your lease! CONGRATS!
        </p>
      </div>
    </div>
  );
};
