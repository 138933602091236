import { API_BASE_URL } from '../constants';

interface QueryParams {
  [key: string]: string | number | undefined;
}

const api = (path: string, params?: QueryParams) =>
  `${API_BASE_URL}/${path}${queryStringify(params)}`;

const queryStringify = (params: QueryParams | undefined) => {
  if (!params) return '';

  let s = '';
  let prefix = '?';

  for (const [key, value] of Object.entries(params)) {
    if (typeof value === 'undefined') continue;
    s += `${prefix}${key}=${value}`;
    prefix = '&';
  }

  return s;
};

export const get = (path: string, params: QueryParams) =>
  fetch(api(path, params), {
    method: 'GET',
  });

export const post = (path: string, body: any) =>
  fetch(api(path), {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
