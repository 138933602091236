import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getEstimate,
  EstimateState,
  getVehicleMake,
  getVehicleValue,
  getPayoffQuote,
} from '../store/estimate';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useFingerprint } from '../hooks/useFingerprint';
import { recordUser } from '../services/record-user.service';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Hero from '../components/next-steps/hero';
import Timeline from '../components/next-steps/timeline';
import Testimonials from '../components/next-steps/testimonials';

export default () => {
  const estimate = useSelector(getEstimate);
  const vehicleMake = useSelector(getVehicleMake);
  const vehicleValue = useSelector(getVehicleValue);
  const payoffQuote = useSelector(getPayoffQuote);
  const fingerprint = useFingerprint();
  const flags = useFeatureFlags();

  useEffect(() => {
    if (!fingerprint || estimate.state === EstimateState.Pending) return;

    recordUser({
      fingerprint,
      vehicleMake,
      vehicleValue,
      payoffQuote,
      monthlyPayment: estimate.monthlyPayment,
      remainingPayments: estimate.remainingPayments,
      replacementCost: estimate.replacementCost,
    });
  }, [fingerprint]);

  return (
    <Layout>
      <SEO title="Get Started" />
      <div className="next-steps">
        <div className="constrain">
          <Hero />
          <Timeline />
        </div>
        {flags.showNextStepsArticles && <Testimonials />}
      </div>
    </Layout>
  );
};
